@import url(https://fonts.googleapis.com/css2?family=Open+Sans&family=Unica+One&display=block);
/* Document
 * ========================================================================== */
/**
 * Add border box sizing in all browsers (opinionated).
 */
*,
::before,
::after {
  box-sizing: border-box;
}
/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit; /* 1 */
  vertical-align: inherit; /* 2 */
}
/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Change the line height in all browsers (opinionated).
 * 3. Use a 4-space tab width in all browsers (opinionated).
 * 4. Remove the grey highlight on links in iOS (opinionated).
 * 5. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 * 6. Breaks words to prevent overflow in all browsers (opinionated).
 */
html {
  cursor: default; /* 1 */
  line-height: 1.5; /* 2 */
  -moz-tab-size: 4; /* 3 */
  tab-size: 4; /* 3 */
  -webkit-tap-highlight-color: transparent /* 4 */;
  -ms-text-size-adjust: 100%; /* 5 */
  -webkit-text-size-adjust: 100%; /* 5 */
  word-break: break-word; /* 6 */
}
/* Sections
 * ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}
/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}
/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}
/**
 * 1. Add the correct sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}
/**
 * Add the correct display in IE.
 */
main {
  display: block;
}
/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none;
  padding: 0;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/* Text-level semantics
 * ========================================================================== */
/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}
/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}
/* Embedded content
 * ========================================================================== */
/*
 * Change the alignment on media elements in all browsers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}
/**
 * Remove the border on iframes in all browsers (opinionated).
 */
iframe {
  border-style: none;
}
/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg:not([fill]) {
  fill: currentColor;
}
/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}
/* Tabular data
 * ========================================================================== */
/**
 * Collapse border spacing in all browsers (opinionated).
 */
table {
  border-collapse: collapse;
}
/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
}
/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}
/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/**
 * 1. Change the inconsistent appearance in all browsers (opinionated).
 * 2. Correct the padding in Firefox.
 */
fieldset {
  border: 1px solid #a0a0a0; /* 1 */
  padding: 0.35em 0.75em 0.625em; /* 2 */
}
/**
 * Show the overflow in Edge 18- and IE.
 */
input {
  overflow: visible;
}
/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}
/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}
/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}
/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 * 3. Change the resize direction in all browsers (opinionated).
 */
textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
  resize: vertical; /* 3 */
}
/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}
/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}
/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
dialog:not([open]) {
  display: none;
}
/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}
/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE.
 */
template {
  display: none;
}
/* User interaction
 * ========================================================================== */
/*
 * 1. Remove the tapping delay in IE 10.
 * 2. Remove the tapping delay on clickable elements
      in all browsers (opinionated).
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation; /* 1 */
  touch-action: manipulation; /* 2 */
}
/* Accessibility
 * ========================================================================== */
/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */
[aria-busy="true"] {
  cursor: progress;
}
/*
 * Change the cursor on control elements in all browsers (opinionated).
 */
[aria-controls] {
  cursor: pointer;
}
/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */
[aria-disabled="true"],
[disabled] {
  cursor: not-allowed;
}
/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */
[aria-hidden="false"][hidden] {
  display: initial;
}
[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

/* || General Styles */

ul {
  margin: 0;
}

button {
  background: none;
  border: none;
  color: white;
}

h1 {
  font-size: 3rem;
  line-height: 2.2rem;
  margin: 0;
  margin-bottom: 10px;
}

a {
  text-decoration: none;
}

a:visited,
a:link {
  color: inherit;
}

h2 {
  font-size: 2.5rem;
  line-height: 1.9rem;
  margin: 0;
  text-align: center;
  margin-bottom: 35px;
}

h3 {
  font-size: 2.5rem;
  margin: 15px 0 5px;
  text-align: center;
}

p {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

.app {
  font-family: 'Unica One';
  background: linear-gradient(to right, #457b9d, #a8dadc);
  color: white;
}

/* || Header */

.site-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to right, #457b9d, #a8dadc);
  z-index: 3;
  border-bottom: 2px solid #ade8f4;
  box-shadow: 0 0 20px rgb(0 0 0 / 16%);
}

.site-header-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 10px;
  -webkit-align-items: center;
          align-items: center;
}

.site-logo {
  height: 20px;
  fill: white;
}

/* || Site Naviagation */

.site-nav-mobile {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.85);
  color: black;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-weight: bold;
  font-size: 3.5rem;
  text-align: center;
  z-index: 2;
}

.site-nav-mobile-x {
  margin-bottom: 40px;
}

.site-nav-desktop {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  font-size: 1.75rem;
}

.site-nav-desktop a:hover {
  border-bottom: 2px solid #ade8f4;
}

.site-nav-link {
  margin: 0;
  margin-bottom: 20px;
  font-size: 3rem;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.site-nav-link a {
  color: black;
}

.site-nav-hidden {
  display: none;
}

.site-nav-desktop > li {
  margin-left: 30px;
  padding: 5px;
}

/* || Home Section */

.site-home {
  height: 100vh;
  position: relative;
  z-index: 1;
}

.site-home-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100%;
}

.home-background {
  z-index: -1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  stroke-width: 4px;
  stroke: #ade8f4;
}

.home-img {
  height: 150px;
  width: 150px;
  border-radius: 100%;
  border: 8px solid white;
  margin-bottom: 20px;
}

/* || About Section */

.site-about,
.site-contact {
  background: white;
  color: black;
  box-shadow: 0 0 20px rgb(0 0 0 / 16%);
}

.site-about-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 40px;
  max-width: 500px;
  margin: 0 auto;
}

.about-headline {
  margin-bottom: 25px;
}

/* || Projects Section */

.site-projects {
  padding: 40px 0;
}

.site-projects-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 40px;
  padding-bottom: 20px;
}

.projects-list {
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(auto-fill);
  grid-gap: 20px;
  gap: 20px;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  padding: 0 20px;
}

.project {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border: 3px solid #ade8f4;
  padding: 20px;
  box-shadow: 0 0 20px rgb(0 0 0 / 16%);
  border-radius: 5px;
}

.project > img {
  width: 100%;

  border: 3px solid #ade8f4;
  border-radius: 5px;
}

.project > p {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.project-button-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  color: black;
}

.project-button-wrapper .project-button:only-child {
  margin-left: auto;
}

.project-button {
  margin-top: 15px;
  border-radius: 5px;
  background-color: white;
  padding: 0 5px;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.project-button:hover {
  background-color: #ade8f4;
}

/* || Contact Section */

.contact-logo {
  height: 50px;
}

.contact-logo:hover {
  fill: #ade8f4;
}

.site-end-logo {
  height: 40px;
}

.site-contact-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding: 40px;
}

.site-contact-icons {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

/* || Footer */

.site-footer {
  padding: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

/* || Project Grid Orientation */

@media (min-width: 500px) {
  .projects-list {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }

  .project-button-wrapper {
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .home-img {
    margin-left: 20px;
  }
}

/* || Medium Size Screens */

@media (min-width: 700px) {
  /* || General Styles */

  h1 {
    font-size: 5.5rem;
    line-height: 4rem;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 4rem;
    line-height: 3rem;
  }

  p {
    font-size: 1.5rem;
  }

  /* || Site Header */

  .site-logo {
    height: 35px;
  }

  /* || Home Section */

  .home-text > p {
    text-align: center;
  }

  .home-img {
    height: 180px;
    width: 180px;
    width: 180;
  }

  /* || Contact Section */

  .site-contact-icons {
    width: 300px;
  }
}

/* || Large Size Screens */

@media (min-width: 1200px) {
  /* || General Styles */

  h1 {
    font-size: 7rem;
    line-height: 6rem;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 5rem;
    line-height: 4rem;
  }

  p {
    font-size: 1.75rem;
  }

  /* || Site Header */

  .site-header-wrapper {
    width: 1200px;
    margin: 0 auto;
  }

  /* || Home Section */

  .home-img {
    height: 210px;
    width: 210px;
    margin-left: 40px;
  }

  /* || About Section */

  .site-about-wrapper {
    max-width: 700px;
  }

  /* || Projects Section */

  .projects-list {
    width: 1200px;
    margin: 0 auto;
  }

  /* || Contact Section */

  .contact-logo {
    height: 60px;
  }

  /* || Site Footer */

  .site-end-logo {
    height: 45px;
  }
}

/* || Site Orientation Responsiveness */

@media screen and (orientation: portrait) {
  .site-home-wrapper {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .home-img {
    margin-left: 0;
  }
}

@media screen and (orientation: landscape) {
  .site-home-wrapper {
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }
  .home-img {
    margin-bottom: 0;
  }
}

